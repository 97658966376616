// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    projectId: 'in8herbs',
    appId: '1:560926667876:web:e050beaef826f8934a9b71',
    databaseURL: 'https://in8herbs.firebaseio.com',
    storageBucket: 'in8herbs.appspot.com',
    locationId: 'us-west2',
    apiKey: 'AIzaSyDX5yhGvN67WDzv-KcdMPrm9XVH2Nudp58',
    authDomain: 'in8herbs.firebaseapp.com',
    messagingSenderId: '560926667876',
    measurementId: 'G-LDZND9NQ1B',
  },
  openAIToken: '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',
  teas_sold : ["Tulsi","Saffron","Pu-erh","Bitter Orange Peel","Licorice","Valerian","Spearmint","Chamomile","Lavender","Rose","Hibiscus","Jasmine","Peppermint","Rooibos","Lemon Balm","Lemongrass","Orange Blossom","Passionflower","Elderflower","Marigold","Linden Flower","Echinacea","Chrysanthemum","Cornflower","Calendula","Dandelion","Yarrow","Dark Chocolate","Chicory","Turmeric","Ashwagandha","Fennel","Ginger","Ashwagandha","Sarapagandha","Cinnamon", "Cardamom", "Nutmeg","Butterfly Pea Flower","Assam","Darjeeling","Earl Grey","Oolong","Green tea","sencha","matcha","gyokuro","genmaicha","Keemun","Ceylon","Yunnan","peony"],
  agreement: {
    privacy: 'we do not share your data with anyone!', 
    text: 'Please read the following carefully before checking the above box. By checking the box, you acknowledge that the information provided by this system is for educational and informational purposes only and is not intended to be construed as medical advice. The data collected and presented by this system is sourced from publicly available information on the internet and is not scientifically validated or guaranteed. Additionally, you understand that any blends created using this system are not intended to diagnose, treat, cure, or prevent any disease, and should not be used as a substitute for professional medical advice. It is important that you consult with your healthcare provider for all health related questions.',
    link: ''
  },
  numberofLinesBeforeLoginRequired: 5,

  chatbot_welcome_message : '<b>Welcome to Shakti\'s Tea Bot!</b> <br/><br/>We are blending age-old wisdom with Artificial Intelligence to help craft a memorable tea experience for you. <br/><br/>Please review and accept our terms, and we\'ll begin tailoring your personalized tea blend! <br/><br/>Tap on one of these Inspiration queries or start typing below:',
  chatbot_welcome_examples: [
    'Could you recommend tea blends that promote healthy skin?',
    'What tea will pair well with heavy pasta dishes like lasagna',
    'Can you suggest unique and special tea blends for a brunch party?',
    'Do you have any tea blends specifically for weight loss?',
    'When is it best to use black tea versus white tea?',
    'Are there any tea blends that change color magically?',
    'Can you suggest tea blends that aid digestion after meals?',
    'What is a good tea for spiritual pursuits?',
    'I\'m interested in trying some new and unusual tea blend'
  ],
  chatbot_permitted_response_length_in_words: 500,
  cookie_prefix: 'shakti_bot',

  event_logging: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
//  import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
